@use 'styles/_colors';

.chip-div { 
	border-radius: 1px;
	background-color: colors.$body-primary;
	box-shadow: 0 0 3px 0.5px rgb(0, 0, 0);
	padding: 8px;
	margin: 4px;
	width: fit-content;
	cursor: pointer;
}

.selected-skill { 
	box-shadow: 0 0 3px 1px rgb(255, 255, 255);
}
.projects-div { 
	margin-left: 64px;
	margin-right: 64px;
}

.projects-grid-div {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-auto-rows: 1fr;
	grid-auto-columns: 1fr;
	gap: 32px;
}
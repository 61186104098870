.footer-div { 
	padding: 16px;
	margin-top: auto;
}

img { 
	max-width: 64px;
	max-height: 64px;
}

.footer-div > a { 
	padding: 16px;
}

.footer-div img { 
	padding-top: 16px;
}
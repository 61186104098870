@use 'styles/colors';

.App {
  text-align: center;
  background-color: colors.$background;
  width: 100%;
  color: colors.$font-primary;
  min-height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
}

hr { 
	border-color: colors.$font-secondary;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-body {
	display: grid;
	grid-template-columns:100%;
	background-color: colors.$body-primary;
	width: 66%;
	margin: auto;
	padding: 0;
	min-height: 100vh;
	box-shadow: 0 0 5px 1px rgb(0, 0, 0);
	overflow: auto;
}

.App-content { 
	
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hidden-span {
	color: #90a8ff;
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
@use "styles/_colors";

.course-table-div { 
	background-color: colors.$body-secondary;
}

.course-table-div table { 
	border-radius: 2px;
	border-collapse: collapse;
	box-shadow: 0 0 5px 1px rgb(0, 0, 0);
	border-style: hidden;
}

.course-table-div th, td { 
	padding: 8px;
	padding-left: 32px;
	padding-right: 32px;
	border: 1px solid;
}

.course-table-div td:nth-child(2) { 
	padding-left: 72px;
	padding-right: 72px
}

@use 'styles/_colors';

.project-card-div { 
	padding: 16px;
	border-radius: 1px;
	box-shadow: 0 0 5px 1px rgb(0, 0, 0);
	background-color: colors.$body-secondary;
}

.project-card-div > a { 
	color: colors.$font-primary;
	word-break: break-all;
}